import { z } from 'zod'

const quotationSchema = z.object({
  id: z.number(),
  state: z.number(),
  stateLabel: z.string(),
  centerName: z.string(),
  client: z.string(),
  clientId: z.number(),
  opportunity: z.string(),
  opportunityId: z.string(),
  staff: z.string(),
  staffLight: z.string(),
  begin: z.string().nullable(),
  createdAt: z.string()
})

export type Quotation = z.infer<typeof quotationSchema>

const quotationsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(quotationSchema)
})

export type QuotationsList = z.infer<typeof quotationsListSchema>

const quotationInfoSchema = z.object({
  id: z.number(),
  status: z.number(),
  interactiveQuoteLink: z.string(),
  opportunityId: z.number(),
  opportunityReference: z.string(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  dueDate: z.string(),
  contactName: z.string(),
  contactEmail: z.string(),
  contactIsValidEmail: z.boolean(),
  language: z.string(),
  languageId: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  customQuotation: z.boolean(),
  discountId: z.number(),
  discountLabel: z.string(),
  discountEnvelop: z.number(),
  discountRate: z.number(),
  discountTotalUsed: z.number(),
  isAClassicDiscount: z.boolean(),
  maxReductionValue: z.number(),
  maxDueDate: z.string()
})

export type QuotationInformation = z.infer<typeof quotationInfoSchema>

const quotationServiceSchema = z.object({
  id: z.number(),
  serviceId: z.number(),
  serviceLabel: z.string(),
  isAvailable: z.boolean()
})

export type QuotationService = z.infer<typeof quotationServiceSchema>

const quotationSolution = z.object({
  id: z.number(),
  status: z.number(),
  statusLabel: z.string(),
  services: z.array(quotationServiceSchema),
  capacity: z.number(),
  surface: z.number(),
  commitmentId: z.number(),
  commitmentLabel: z.string(),
  commitmentReal: z.number(),
  begin: z.string(),
  deadline: z.string(),
  price: z.number(),
  reduction: z.number(),
  reductionPrice: z.number(),
  staffLabelledName: z.string(),
  isTransformable: z.boolean(),
  disableCommitment: z.boolean()
})

export type QuotationSolution = z.infer<typeof quotationSolution>
const quotationSolutionsSchema = z.array(quotationSolution)
export type QuotationSolutions = z.infer<typeof quotationSolutionsSchema>

const quotationSolutionPriceSchema = z.object({
  service: z.string(),
  price: z.number(),
  priceReduced: z.number(),
  reductionValue: z.number(),
  oldReductionValue: z.number()
})

export type QuotationSolutionPrice = z.infer<typeof quotationSolutionPriceSchema>
