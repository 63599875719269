import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { OccupancyChart } from 'modules/stats/components/occupancy-chart.component'
import { RangePicker } from 'app/components/filters/range-picker'

export const StatsRateOccupancyView = () => {
  const { filtersList, initFilters, handleFilter } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { t, i18n } = useTranslation()
  const { user } = useApp()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )
  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: false,
        default: user?.mainCenter
      }
    }
  }, [user])

  const refreshList = useCallback(async () => {}, [])

  useEffect(() => {
    initFilters(commonFilters, paramsFilters).then()
  }, [i18n.language])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('occupation_rate')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="date"
            label={t('Date')}
            slugs={{ begin: 'date_begin', end: 'date_end' }}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            isNoDate={false}
            valueLabel={'Les 12 derniers mois'}
          />
        </FiltersBox>
      </Grid>
      <OccupancyChart height={350} params={searchParams} />
    </Container>
  )
}
