import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box, Container, Grid, Paper as MuiPaper, Tooltip, Typography } from '@mui/material'
import { useFeedback } from 'app/providers/feedback.provider'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { CaChartComponent } from 'modules/stats/components/ca-chart.component'
import { StatsCA } from 'api/models/stats'
import { useApp } from 'app/providers/app.provider'
import { OccupancyChart } from 'modules/stats/components/occupancy-chart.component'
import { RangePicker } from 'app/components/filters/range-picker'
import { SurfaceChart } from 'modules/stats/components/surface-chart.component'
import { PipelineSheetComponent } from 'modules/stats/components/pipeline-sheet.component'
import { OpenInFullOutlined, Info } from '@mui/icons-material'
import { Link } from 'app/components/link.component'

export const StatsHealthCenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { setSearchParams, searchParams, getStatsCA } = useFetcher()
  const [rows, setRows] = useState<StatsCA>()

  const { handleMutation } = useFeedback()

  const { filtersList, resetFilters, handleFilter } = useList()

  const refreshView = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsCA,
      onSuccess: (data) => {
        setRows(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsCA, handleMutation])

  const getInitialCenterValue = () => {
    const center = searchParams.get('center')
    if (center) return parseInt(center)
    return user?.mainCenter
  }

  useEffect(() => {
    setIsLoading(true)
    resetFilters().then(() => refreshView().then())
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('dashboard_health_center')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshView, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={async () => {
              if (searchParams.get('center') == null) {
                searchParams.set('center', `${user?.mainCenter}`)
                searchParams.set('centers', `[${user?.mainCenter}]`)
              } else searchParams.set('centers', `[${searchParams.get('center')}]`)
              await handleFilter(refreshView, true)
            }}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            initialValue={getInitialCenterValue()}
          />
          <RangePicker
            slug="date"
            label={t('Date')}
            slugs={{ begin: 'date_begin', end: 'date_end' }}
            onChange={() => handleFilter(refreshView, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            isNoDate={false}
            valueLabel={'Les 12 derniers mois'}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('occupation_rate')}</Typography>
                <Link
                  to={`/statsrateoccupancy?date_begin=${searchParams.get(
                    'date_begin'
                  )}&date_end=${searchParams.get('date_end')}`}
                  style={{ zIndex: 10 }}
                >
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <OccupancyChart height={280} params={searchParams} />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
                <Link
                  to={`/stats-ca?date_begin=${searchParams.get(
                    'date_begin'
                  )}&date_end=${searchParams.get('date_end')}`}
                >
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <CaChartComponent
                rows={rows}
                isLoading={isLoading}
                height={280}
                params={searchParams}
              />
            </MuiPaper>
          </Grid>
        </Grid>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('price')} / m2</Typography>
                <Link
                  to={`/stats-surface?centers=${searchParams.get(
                    'centers'
                  )}&date_begin=${searchParams.get('date_begin')}&date_end=${searchParams.get(
                    'date_end'
                  )}`}
                >
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <SurfaceChart height={280} params={searchParams} />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                gap={4}
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('actual_pipeline')}</Typography>
                <Tooltip title={t('tooltip_actual_pipeline')} placement="top-start">
                  <Info fontSize={'small'} />
                </Tooltip>
              </Box>
              <PipelineSheetComponent height={290} params={searchParams} />
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
