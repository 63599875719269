import { z } from 'zod'

const endorsementSchema = z.object({
  amount_difference: z.string(),
  surface_difference: z.string(),
  commitment_difference: z.string(),
  guarantees_difference: z.string(),
  rate: z.string(),
  reason: z.string(),
  workforce_difference: z.string(),
  endorsement_id: z.string(),
  endorsementType: z.string(),
  endorsementReference: z.string(),
  begin: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  center: z.string(),
  parent_id: z.string(),
  parentReference: z.string(),
  state: z.string(),
  created_at: z.string(),
  agreement_type: z.string(),
  stateLabel: z.string(),
  endorsementTypeLabel: z.string(),
  agreementTypeLabel: z.string()
})

export type Endorsement = z.infer<typeof endorsementSchema>

const endorsementListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(endorsementSchema)
})

export type EndorsementList = z.infer<typeof endorsementListSchema>

const endorsementCommissionSchema = z.object({
  id: z.string(),
  reference: z.string(),
  parentId: z.string(),
  parentReference: z.string(),
  client: z.string(),
  clientId: z.string(),
  center: z.string(),
  reason: z.string(),
  staff: z.string(),
  confirm: z.string(),
  begin: z.string(),
  end: z.string(),
  parentBegin: z.string(),
  parentEnd: z.string(),
  surface: z.string(),
  commitment_real: z.string(),
  global_reduction: z.string(),
  price: z.string(),
  surface_difference: z.string(),
  stateLabel: z.string(),
  commitment_difference: z.string(),
  amount_difference: z.string(),
  agreementType: z.string()
})

export type EndorsementCommission = z.infer<typeof endorsementCommissionSchema>

const endorsementCommissionListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(endorsementCommissionSchema)
})

export type EndorsementCommissionList = z.infer<typeof endorsementCommissionListSchema>

const endorsementContractOptionSchema = z.object({
  id: z.number(),
  serviceId: z.number(),
  label: z.string(),
  serviceTypeLabel: z.string(),
  quantity: z.number(),
  reduction: z.number(),
  price: z.number(),
  typology: z.string().nullable()
})

const endorsementContractMainServiceSchema = z.object({
  id: z.number(),
  serviceId: z.number(),
  label: z.string(),
  serviceTypeReference: z.string(),
  serviceTypeLabel: z.string(),
  typology: z.string(),
  surface: z.number(),
  occupants: z.number(),
  previousPrice: z.number(),
  reduction: z.number(),
  price: z.number()
})

const endorsementContractSchema = z.object({
  id: z.number(),
  endorsementLinkId: z.number(),
  endorsementLinkReason: z.number(),
  createdAt: z.string(),
  directionAgreement: z.string(),
  confirm: z.string(),
  confirmState: z.string(),
  agreement: z.string(),
  begin: z.string(),
  deadline: z.string(),
  commitmentId: z.number(),
  commitmentDifference: z.number(),
  workforceDifference: z.number(),
  contractMainPrice: z.number(),
  contractGuarantees: z.number(),
  endorsementMainPrice: z.number(),
  endorsementGuarantees: z.number(),
  amountDifference: z.number(),
  guaranteesDifference: z.number(),
  originalContractServiceIds: z.string(),
  discountGridId: z.number(),
  discountGridLabel: z.string(),
  gridType: z.number(),
  centerPrice: z.number(),
  endorsementType: z.number(),
  mainContractServices: z.array(endorsementContractMainServiceSchema),
  optionContractServices: z.array(endorsementContractOptionSchema),
  statusId: z.number(),
  contractWorkforce: z.number()
})

export type EndorsementContract = z.infer<typeof endorsementContractSchema>

const commitmentSchema = z.object({
  label: z.string(),
  enum: z.number(),
  min: z.number(),
  max: z.number(),
  reference: z.string(),
  rate: z.number()
})
const selectCommitmentsSchema = z.record(z.string(), commitmentSchema)
export type SelectCommitments = z.infer<typeof selectCommitmentsSchema>
