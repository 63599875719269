import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFeedback } from 'app/providers/feedback.provider'
import { ActualPipeline, PipelineStep } from 'api/models'
import { Link } from 'app/components/link.component'

export const PipelineSheetComponent = ({
  height,
  params
}: {
  height: number
  params: URLSearchParams
}): React.JSX.Element => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getActualPipeline } = useFetcher()
  const [pipeline, setPipeline] = useState<ActualPipeline>()

  const { handleMutation } = useFeedback()

  const fetchPipeline = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getActualPipeline,
      onSuccess: (data: ActualPipeline) => {
        setPipeline(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getActualPipeline])

  const createLink = (step: PipelineStep | undefined) => {
    if (!step) return '/opportunities'
    return `/opportunities?status=2&step=${step.id}&surface=0&center=${params.get(
      'center'
    )}&staff=0`
  }

  useEffect(() => {
    fetchPipeline().then()
  }, [params])

  return (
    <>
      {isLoading ? (
        <Card sx={{ overflow: 'visible', marginTop: 4 }}>
          <CardContent>
            <CardSkeleton height={height} />
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ overflow: 'visible', marginTop: 4 }}>
          <CardContent sx={{ height: height, paddingLeft: '24px' }}>
            <Box className="line">
              <Grid container spacing={10}>
                <Grid container item sx={{}}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{'Total'}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{'m2'}</Typography>
                  </Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4} style={{ borderBottom: '1px solid' }}></Grid>
                  <Grid item xs={4} style={{ borderBottom: '1px solid' }}></Grid>
                  <Grid item xs={4} style={{ borderBottom: '1px solid' }}></Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4}>
                    <Link to={createLink(pipeline?.contract)}>
                      <Typography variant="h4">{t('contracts')}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.contract?.count ?? 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.contract?.totalSurface ?? 0}</Typography>
                  </Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4}>
                    <Link to={createLink(pipeline?.visit)}>
                      <Typography variant="h4">{t('visits')}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.visit?.count ?? 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.visit?.totalSurface ?? 0}</Typography>
                  </Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4}>
                    <Link to={createLink(pipeline?.quotation)}>
                      <Typography variant="h4">{t('quotation')}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.quotation?.count ?? 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.quotation?.totalSurface ?? 0}</Typography>
                  </Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4}>
                    <Link to={createLink(pipeline?.leadQualified)}>
                      <Typography variant="h4">{'Leads ' + t('qualified')}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.leadQualified?.count ?? 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">
                      {pipeline?.leadQualified?.totalSurface ?? 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sx={{}}>
                  <Grid item xs={4}>
                    <Link to={createLink(pipeline?.lead)}>
                      <Typography variant="h4">{'Leads'}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.lead?.count ?? 0}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{pipeline?.lead?.totalSurface ?? 0}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  )
}
