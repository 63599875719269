import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent as MuiCardContent, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ApexOptions } from 'apexcharts'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Occupations } from 'api/models/stats'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import getLocales from 'app/utils/apexlocales.util'
import { ThemedChart } from 'app/components/charts/timeline.component'

const CardContent = styled(MuiCardContent)`
  position: relative;
`

export const OccupancyChart = ({ height, params }: { height: number; params: URLSearchParams }) => {
  const { getStatsOccupations, searchParams, setSearchParams } = useFetcher()
  const [occupations, setOccupations] = useState<any>([])
  const { t, i18n } = useTranslation()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const [locales, setLocales] = useState<any>([])

  const labels = useMemo(() => {
    let startDate = dayjs().startOf('month')
    const dateBegin = searchParams.get('date_begin')
    if (dateBegin !== null) {
      startDate = dayjs(dateBegin).startOf('month')
    }
    const dates = []
    for (let i = 0; i < 12; i++) {
      const formattedDate = startDate.add(i, 'month').format('MM/DD/YYYY')
      dates.push(formattedDate)
    }
    return dates
  }, [dayjs, searchParams, listIsLoading])

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    const response = await getStatsOccupations.mutateAsync()
    formatData(response)
  }, [getStatsOccupations])

  useEffect(() => {
    refreshList().then()
  }, [params])

  useEffect(() => {
    searchParams.set('serviceTypes', JSON.stringify(['FULL_DESKTOP']))
    searchParams.set('contractTypes', JSON.stringify([1, 2]))

    getLocales().then((locales: any) => setLocales(locales))
    setSearchParams(searchParams)
    refreshList().then()
  }, [i18n.language])

  const formatData = useCallback((data: Occupations) => {
    let occupiedRate: number[] = [],
      renewedRate: number[] = []
    let targetRate: number[] = []
    let totalRate: number[] = []
    data.forEach((item) => {
      let currentItem
      if (item.items.length > 2) {
        currentItem = item.items[item.items.length - 1]
      } else {
        currentItem = item.items[0]
      }
      let renewed = currentItem.occupiedRate - currentItem.renewedRate
      let occupied = currentItem.renewedRate
      totalRate.push(renewed + occupied)
      occupiedRate.push(occupied)
      renewedRate.push(renewed)
      targetRate.push(currentItem.targetRate)
    })
    const formatData = [
      {
        name: t('in_progress'),
        type: 'bar',
        data: occupiedRate,
        color: '#227fd2'
      },
      {
        name: t('renewed'),
        type: 'bar',
        data: renewedRate,
        color: '#98d9d9'
      },
      {
        name: t('bp'),
        type: 'line',
        data: targetRate,
        color: '#ff8219'
      },
      {
        name: t('total_contracts'),
        type: 'scatter',
        data: totalRate
      }
    ]
    setOccupations(formatData)
    setListIsLoading(false)
  }, [])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        stacked: true,
        locales: [locales],
        defaultLocale: i18n.language
      },
      stroke: {
        width: [0, 0, 1],
        curve: 'smooth'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%'
        }
      },
      legend: {
        position: 'top'
      },
      yaxis: {
        labels: {
          formatter: (val) => Math.floor(val) + ' %'
        }
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        labels: {
          format: 'MMM yyyy'
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          },
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(2) + ' %'
            }
            return y
          }
        }
      },
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      }
    }
    return data
  }, [labels, theme.palette.mode, i18n.language, locales])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            {listIsLoading ? (
              <CardSkeleton height={height} />
            ) : (
              <Box className="line">
                <ThemedChart options={options} series={occupations} type="line" height={height} />
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
